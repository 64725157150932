import { App } from 'vue';

import pButton from 'primevue/button';
import pInputText from 'primevue/inputtext';
import pTextarea from 'primevue/textarea';
import pMultiSelect from 'primevue/multiselect';
import pPassword from 'primevue/password';
import pMessage from 'primevue/message';
import pInlineMessage from 'primevue/inlinemessage';
import pInputMask from 'primevue/inputmask';
import pInputNumber from 'primevue/inputnumber';
import pAvatar from 'primevue/avatar';
import pAvatarGroup from 'primevue/avatargroup';
import pDivider from 'primevue/divider';
import pDropdown from 'primevue/dropdown';
import pMenu from 'primevue/menu';
import pTooltip from 'primevue/tooltip';
import pCheckbox from 'primevue/checkbox';
import pInputSwitch from 'primevue/inputswitch';
import pSteps from 'primevue/steps';
import pChart from 'primevue/chart';
import pDataTable from 'primevue/datatable';
import pDataView from 'primevue/dataview';
import pDataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import pColumn from 'primevue/column';
import pProgressBar from 'primevue/progressbar';
import pSkeleton from 'primevue/skeleton';
import pCalendar from 'primevue/calendar';
import pCard from 'primevue/card';
import pSidebar from 'primevue/sidebar';
import pFileUpload from 'primevue/fileupload';
import pPaginator from 'primevue/paginator';
import pBreadcrumb from 'primevue/breadcrumb';
import pDialog from 'primevue/dialog';
import pConfirmPopup from 'primevue/confirmpopup';
import pToast from 'primevue/toast';
import pConfirmDialog from 'primevue/confirmdialog';
import pTabView from 'primevue/tabview';
import pTabPanel from 'primevue/tabpanel';
import pBadge from 'primevue/badge';
import pFieldset from 'primevue/fieldset';
import pImage from 'primevue/image';
import pBadgeDirective from 'primevue/badgedirective';
import pTree from 'primevue/tree';
import pTreeTable from 'primevue/treetable';
import pChip from 'primevue/chip';
import pChips from 'primevue/chips';
import pToggleButton from 'primevue/togglebutton';
import pProgressSpinner from 'primevue/progressspinner';
import pTimeline from 'primevue/timeline';
import pContextMenu from 'primevue/contextmenu';
import pListbox from 'primevue/listbox';
import pCarousel from 'primevue/carousel';
import pTriStateCheckbox from 'primevue/tristatecheckbox';
import pTag from 'primevue/tag';
import pRadioButton from 'primevue/radiobutton';
import pMenubar from 'primevue/menubar';
import pAccordion from 'primevue/accordion';
import pAccordionTab from 'primevue/accordiontab';
import pColorPicker from 'primevue/colorpicker';
import pScrollPanel from 'primevue/scrollpanel';
import pSplitButton from 'primevue/splitbutton';
import pAutoComplete from 'primevue/autocomplete';
import pSpeedDial from 'primevue/speeddial';
import pSelectButton from 'primevue/selectbutton';
import pPanel from 'primevue/panel';
import pSplitter from 'primevue/splitter';
import pSplitterPanel from 'primevue/splitterpanel';
import pOverlayPanel from 'primevue/overlaypanel';
import pOrderList from 'primevue/orderlist';
import pPickList from 'primevue/picklist';
import pTreeSelect from 'primevue/treeselect';
import pInplace from 'primevue/inplace';
import pVirtualScroller from 'primevue/virtualscroller';
import pTabMenu from 'primevue/tabmenu';
import pEditor from 'primevue/editor';
import OrganizationChart from 'primevue/organizationchart';
import InputIcon from 'primevue/inputicon';
import IconField from 'primevue/iconfield';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';

import PrimeVue from 'primevue/config';

export function installPrimeVue(app: App) {
  app.use(PrimeVue);
  app.component('PButton', pButton);
  app.component('PInputText', pInputText);
  app.component('PTextarea', pTextarea);
  app.component('PMultiselect', pMultiSelect);
  app.component('PPassword', pPassword);
  app.component('PMessage', pMessage);
  app.component('PInlineMessage', pInlineMessage);
  app.component('PInputMask', pInputMask);
  app.component('PInputNumber', pInputNumber);
  app.component('PCard', pCard);
  app.component('PAvatar', pAvatar);
  app.component('PAvatarGroup', pAvatarGroup);
  app.component('PDivider', pDivider);
  app.component('PDropdown', pDropdown);
  app.component('PMenu', pMenu);
  app.component('PCheckbox', pCheckbox);
  app.component('PInputSwitch', pInputSwitch);
  app.component('PSteps', pSteps);
  app.component('PChart', pChart);
  app.component('PDataTable', pDataTable);
  app.component('PDataView', pDataView);
  app.component('PDataViewLayoutOptions', pDataViewLayoutOptions);
  app.component('PColumn', pColumn);
  app.component('PProgressBar', pProgressBar);
  app.component('PSkeleton', pSkeleton);
  app.component('PCalendar', pCalendar);
  app.component('PSidebar', pSidebar);
  app.component('PFileUpload', pFileUpload);
  app.component('PPaginator', pPaginator);
  app.component('PBreadcrumb', pBreadcrumb);
  app.component('PDialog', pDialog);
  app.component('PConfirmPopup', pConfirmPopup);
  app.component('PToast', pToast);
  app.component('PConfirmDialog', pConfirmDialog);
  app.component('PTabView', pTabView);
  app.component('PTabPanel', pTabPanel);
  app.component('PBadge', pBadge);
  app.component('PFieldset', pFieldset);
  app.component('PTree', pTree);
  app.component('PTreeTable', pTreeTable);
  app.component('PTreeSelect', pTreeSelect);
  app.component('PChip', pChip);
  app.component('PChips', pChips);
  app.component('PToggleButton', pToggleButton);
  app.component('PProgressSpinner', pProgressSpinner);
  app.component('PTimeline', pTimeline);
  app.component('PContextMenu', pContextMenu);
  app.component('PListbox', pListbox);
  app.component('PCarousel', pCarousel);
  app.component('PTriStateCheckbox', pTriStateCheckbox);
  app.component('PTag', pTag);
  app.component('PRadioButton', pRadioButton);
  app.component('PMenubar', pMenubar);
  app.component('PAccordion', pAccordion);
  app.component('PAccordionTab', pAccordionTab);
  app.component('PColorPicker', pColorPicker);
  app.component('PScrollPanel', pScrollPanel);
  app.component('PSplitButton', pSplitButton);
  app.component('PAutoComplete', pAutoComplete);
  app.component('PImage', pImage);
  app.component('PSpeedDial', pSpeedDial);
  app.component('PSelectButton', pSelectButton);
  app.component('PPanel', pPanel);
  app.component('PSplitter', pSplitter);
  app.component('PSplitterPanel', pSplitterPanel);
  app.component('POverlayPanel', pOverlayPanel);
  app.component('POrderList', pOrderList);
  app.component('PPickList', pPickList);
  app.component('PInplace', pInplace);
  app.component('PVirtualScroller', pVirtualScroller);
  app.component('PTabMenu', pTabMenu);
  app.component('PEditor', pEditor);
  app.component('POrganizationChart', OrganizationChart);
  app.component('PInputIcon', InputIcon);
  app.component('PIconField', IconField);
  app.component('PInputGroup', InputGroup);
  app.component('PInputGroupAddon', InputGroupAddon);

  app.directive('tooltip', pTooltip);
  app.directive('badge', pBadgeDirective);
}
