<script setup lang="ts">
import { KeyValueOptions } from 'platform-unit2-api/attributes';
import { TranslationService } from '@/general/services/translations/translation.service';

interface Props {
  multiSelect?: boolean;
  choices?: (KeyValueOptions | string)[];
}

withDefaults(defineProps<Props>(), {
  multiSelect: false,
  choices: () => [],
});

const emit = defineEmits<{
  (e: 'update:multiSelect', value: boolean): void;
  (e: 'update:values', value: KeyValueOptions[] | undefined): void;
}>();

const ts = new TranslationService('supplier', 'datamodels');
</script>

<template>
  <div class="w-full">
    <div class="align-items-center field flex mb-3">
      <label class="font-bold mr-3 text-sm">
        {{ ts.tModule('datamodels_overwrite.form.select_type') }}
      </label>

      <div>
        <pInputSwitch
          :model-value="multiSelect"
          @update:model-value="(value: boolean) => emit('update:multiSelect', value)"
        />
      </div>
    </div>

    <label class="font-bold text-sm">{{
      ts.tModule('datamodels_overwrite.form.pre_defined_options')
    }}</label>
    <div class="overflow-y-scroll pt-3 relative">
      <div
        v-for="(choice, index) in (choices as KeyValueOptions[])"
        :key="index"
        class="align-items-center flex mb-3"
      >
        <div class="p-inputgroup">
          <span class="mr-1 p-inputgroup-addon">Key</span>
          <pInputText
            v-model="choice.key"
            placeholder="Key"
            disabled
            @update:model-value="
              () => {
                emit('update:values', choices as KeyValueOptions[]);
              }
            "
          />
        </div>
        <span style="color: #708da9" class="mx-2">
          <i class="mdi mdi-arrow-right"></i>
        </span>
        <div class="mr-1 p-inputgroup">
          <span class="mr-1 p-inputgroup-addon">Value</span>
          <pInputText
            :model-value="choice.value"
            placeholder="Value"
            disabled
            @update:model-value="
              () => {
               emit('update:values', choices as KeyValueOptions[]);
              }
            "
          />
        </div>
        <!-- Currently editing of key value pairs is disabled and not visible to the user -->
        <pButton
          v-if="false"
          text
          plain
          rounded
          class="flex-shrink-0"
          icon="mdi mdi-delete-outline"
          disabled
        />
      </div>
      <!-- Currently editing of key value pairs is disabled and not visible to the user -->
      <pButton
        v-if="false"
        plain
        outlined
        class="bg-white block border-dashed bottom-0 mt-3 sticky w-full"
        :label="ts.tModule('addPair')"
        icon="mdi mdi-plus"
        disabled
      />
    </div>
  </div>
</template>
