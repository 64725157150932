<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import { inject } from 'vue';

const ts = inject(tsKey)!;
</script>
<template>
  <pConfirmDialog v-bind="{ ...$attrs }" class="dialog-size-small">
    <template #message>
      <div class="bg-white flex flex-column gap-3 my-3 surface-border">
        <p v-html="ts.tModule('deletedProducts.restoreVariantMessage')" />
        <pMessage severity="error" :closable="false" class="w-full">
          <template #messageicon>
            <i class="mdi mdi-information mr-3 text-3xl text-red-500"></i>
          </template>
          <p>
            {{ ts.tModule('deletedProducts.restoreVariantInfo') }}
          </p>
        </pMessage>
      </div>
    </template>
  </pConfirmDialog>
</template>
