<script setup lang="ts">
import { inject, ref } from 'vue';
import { TranslationService } from 'services/translations/translation.service';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { ChoiceFieldService } from 'services/attribute-fields/field-services/choice-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import WrapperField from './wrapper-field.vue';

/** Props */
interface Props {
  attributeField: AttributeField<string | undefined>;
  productAttributeField: ProductAttributeField<string | undefined>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const choiceFieldService = ref(
  new ChoiceFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
const ts = new TranslationService('general', 'components');
</script>

<template>
  <WrapperField :attribute-field-service="choiceFieldService">
    <pDropdown
      v-model="choiceFieldService.productAttributeFieldValue"
      filter
      reset-filter-on-hide
      :options="choiceFieldService.attributeField.attribute.options?.choices"
      :disabled="choiceFieldService.productAttributeField.locked"
      :placeholder="ts.tModule('attributeFields.chooseValue')"
      :show-clear="
        choiceFieldService.productAttributeField.value != null &&
        !choiceFieldService.productAttributeField.locked
      "
      :class="choiceFieldService.getErrorTypeClass()"
      :pt="{
        clearIcon: () => ({
          class: 'z-2',
        }),
        input: {
          style: 'width: 100px',
        },
        panel: {
          class: 'max-w-full',
        },
        itemLabel: {
          style: 'overflow: hidden; text-wrap: wrap;',
        },
      }"
    >
      <template #empty> {{ ts.notFoundWarning }}</template>
      <template #value="{ value, placeholder }">
        <div v-if="value != null">{{ value }}</div>
        <div v-else>{{ placeholder }}</div>
      </template>
    </pDropdown>
  </WrapperField>
</template>
