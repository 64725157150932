import { BaseViewService } from '../view/base-view.service';
import { TranslationService } from '../../translations/translation.service';
import store from '@/core/store';
import { User } from 'platform-unit2-api/users';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import { Client } from 'platform-unit2-api/clients';
import { Product } from 'platform-unit2-api/products';
import { UserRestService } from 'platform-unit2-api/users';
import { FilterMatchMode } from 'primevue/api';
import { DataTableFilterEvent, DataTableFilterMetaData } from 'primevue/datatable';
import { isPositive } from '@/general/utils/isPositive';
import { ListResponse } from 'platform-unit2-api/core';
import {
  Action,
  ActionFilters,
  ActionRestService,
  CreateTaskAction,
  UpdateActionRequest,
} from 'platform-unit2-api/actions';
import { Platform } from '@/core/router/route.factory';

export class TaskViewService extends BaseViewService<
  ActionRestService,
  Action,
  CreateTaskAction,
  UpdateActionRequest & { id: number }
> {
  selectedProduct?: Product;
  isAssignedToWorkspace = false;
  users: User[] = [];
  productsIds: number[] = [];
  externalPage?: string;
  currentUser?: User;
  assignableUsers: User[] = [];
  filter = '';
  filters = {
    'status.label': { value: null, matchMode: FilterMatchMode.EQUALS },
    'action_type.name': { value: null, matchMode: FilterMatchMode.EQUALS },
    users: { value: null, matchMode: FilterMatchMode.EQUALS },
  };
  filterParams: ActionFilters = {};

  constructor(page?: string, ts?: TranslationService) {
    const currentUser: User | undefined = store.getters['users/currentUser'];
    let currentSpace: ClientTypeEnum =
      currentUser?.workspace?.workspace_type?.type ?? ClientTypeEnum.SUPPLIER;
    if (currentSpace.toString().toLowerCase() === 'dms') {
      currentSpace = ClientTypeEnum.SUPPLIER;
    }

    super({
      Api: ActionRestService,
      fetchAllFunction: 'getAll',
      ts: ts ?? new TranslationService(currentSpace.toLowerCase() as Platform, 'tasks'),
      confirmPopUpGroup: 'tasks',
      createRouteName: (page ? page + '-' : '') + 'new-task',
      updateRouteName: (page ? page + '-' : '') + 'update-task',
      overviewRouteName: page ? page : 'tasks',
    });

    this.externalPage = page;
    this.refetch = this.fetchAll;
    this.currentUser = currentUser;
    this.fetchAsignableUsers();
  }

  public get tasks() {
    return this._data;
  }

  public get partialObject(): Partial<Action> {
    if (this.current == null) {
      this.current = {} as Action;
    }

    if (!this.current?.owner) {
      this.current!.owner = {} as Client;
    }

    if (!this.current?.products) {
      this.current!.products = [];
    }

    return this.current;
  }

  public get createTaskChildRoutes() {
    return [
      {
        label: this._ts.tModule('addTask.describe'),
        to: {
          name: (this.externalPage ? this.externalPage + '-' : '') + 'new-task-describe',
        },
      },
      {
        label: this._ts.tModule('addTask.assign'),
        to: {
          name: (this.externalPage ? this.externalPage + '-' : '') + 'new-task-assign',
        },
      },
      {
        label: this._ts.tModule('addTask.settings'),
        to: {
          name: (this.externalPage ? this.externalPage + '-' : '') + 'new-task-settings',
        },
      },
    ];
  }

  public get disableDescribePageButtonCondition() {
    return (
      this.partialObject.action_type == null ||
      this.partialObject.title == null ||
      this.partialObject.title == ''
    );
  }

  public get disableAssignPageButtonCondition() {
    return this.users?.length === 0 && !this.isAssignedToWorkspace;
  }

  public get getDisabledButtonsConditions() {
    return [
      {
        pageIndex: 0,
        disableNextStep: this.disableDescribePageButtonCondition,
      },
      {
        pageIndex: 1,
        disableNextStep: this.disableAssignPageButtonCondition,
      },
      {
        pageIndex: 2,
        disableNextStep: this.partialObject.date == null,
      },
    ];
  }

  public get isCreating(): boolean {
    return (this._route.name ?? '').toString().includes(this._createRouteName);
  }

  public get resolveCrudComponentCondition(): boolean {
    const prevRoute: string = (this._router.options.history.state['back'] as string) ?? '';
    return (
      (this.createTaskChildRoutes
        .map((child) => child.to.name)
        .includes(this._route.name?.toString() ?? '') &&
        prevRoute === '/tasks') ||
      this._route.name === this._updateRouteName
    );
  }

  public get assignees() {
    return [
      {
        label: this._ts.tModule('currentWorkspace'),
        items: [{ label: this.currentUser?.workspace?.name, value: 'workspace' }],
      },
      {
        label: this._ts.tGlobal('user'),
        items: this.assignableUsers.map((user) => ({
          label: user.name,
          value: user.id,
        })),
      },
    ];
  }

  public createBody(): CreateTaskAction | undefined {
    return {
      title: this.partialObject.title!,
      action_type_id: this.partialObject.action_type!.id,
      user_ids: this.users?.map((user) => user.id),
      client_id: this.isAssignedToWorkspace ? this.currentUser?.workspace?.id : undefined,
      description: this.partialObject.description,
      date: this.partialObject.date!,
      ids: this.productsIds,
      ids_type: 'product',
    };
  }

  public updateBody(): (UpdateActionRequest & { id: number }) | undefined {
    return {
      id: Number(this._route.params.id),
      status: this.partialObject.status!.value,
    };
  }

  public get validated(): boolean {
    return true;
  }

  public closeCrudComponent(): void {
    super.closeCrudComponent();
    this.users = [];
    this.isAssignedToWorkspace = false;
  }

  /**
   * Open sidebar, also fetches the object if the id is set when reloading page
   * @param object set current object
   */
  public openCrudComponent(object?: Action | Partial<Action> | undefined, redirect = true): void {
    this._formValidationService.resetErrors();

    //If page is refreshed and the id is set, fetch the objects
    //Router doesn't need to be called because user is already on update route
    if (object == null && this._route.params?.id != null && isPositive(this._route.params.id)) {
      this._get(Number(this._route.params.id));
      return;
    }

    //set the current object
    this.current = object;

    //Route to child component to store the id in the url
    if (redirect) {
      if (this.current != null && this.current?.id != null) {
        this._router.push({ name: this._updateRouteName, params: { id: this.current.id } });
        this.crudComponent = true;
      } else {
        this._router.push({ name: this._createRouteName });
      }
    }
  }

  public deleteAction(id: number): void {
    this._confirmService.confirmDelete({
      callback: () => {
        this._restService
          .delete(id)
          .then((_) => {
            this._isLoadingOverview = true;
            this._toastService.displaySuccessToast(this._ts.deleteSuccess());
            this.refetch();
          })
          .catch(() => {
            this._toastService.displayErrorToast(this._ts.deleteFailed());
          })
          .finally(() => {
            this._isLoadingOverview = false;
          });
      },
      group: 'import-dialog',
    });
  }

  public styleDate(date: string, completed: string): string {
    const dueDate = new Date(date).setHours(0, 0, 0, 0);

    if (completed != null) {
      const completedAt = new Date(completed).setHours(0, 0, 0, 0);
      if (dueDate <= completedAt) {
        return 'has-text-danger';
      } else {
        return 'has-text-success';
      }
    } else {
      const now = new Date().setHours(0, 0, 0, 0);
      if (dueDate <= now) {
        return 'has-text-danger';
      }
    }

    return 'has-text-warning';
  }

  private fetchAsignableUsers(): void {
    const userRestService = new UserRestService();
    userRestService
      .getAll()
      .then((res) => {
        this.assignableUsers = res.data;
      })
      .catch(() =>
        this._toastService.displayErrorToast(this._ts.loadFailed(this._ts.tGlobal('user'))),
      );
  }

  public fetchAll(): void {
    this._isLoadingOverview = true;

    this._restService
      .getAll(this._fetchVariables, this.filterParams)
      .then((response: ListResponse<Action>) => {
        this._data = response.data;
        this.total = response.meta?.total;
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
        this._data = [];
      })
      .finally(() => {
        this._isLoadingOverview = false;
        this.isInitialized = true;
      });
  }

  public applyFilter(event: DataTableFilterEvent) {
    this.filterParams = {
      ...this.filterParams,
      statuses: (event.filters['status.label'] as DataTableFilterMetaData)?.value?.map(
        (status: { value: string }) => status.value,
      ),
      types: (event.filters['action_type.name'] as DataTableFilterMetaData)?.value?.map(
        (type: { id: number }) => type.id,
      ),
      user_ids: (event.filters['users'] as DataTableFilterMetaData)?.value
        ?.filter((val: { value: string }) => val.value !== 'workspace')
        .map((user: { value: string }) => user.value),
      workspace_id: (event.filters['users'] as DataTableFilterMetaData)?.value?.find(
        (val: { value: string }) => val.value === 'workspace',
      )?.value,
    };

    if (
      (event.filters['users'] as DataTableFilterMetaData)?.value?.find(
        (val: { value: string }) => val.value === 'workspace',
      ) != null
    ) {
      this.filterParams.workspace_id = this.currentUser?.workspace?.id;
    }

    this.refetch();
  }
}
