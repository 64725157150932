import {
  ImportRestService,
  ImportPipeline,
  CreateImportPipeline,
  RestartImportRequest,
  ImportFilters,
} from 'platform-unit2-api/imports';
import { BaseViewService } from '../view/base-view.service';
import { TranslationService } from '../../translations/translation.service';
import { FileUploadUploaderEvent } from 'primevue/fileupload';
import { UploadsRestService, CreateUploadRequest } from 'platform-unit2-api/uploads';
import { CoreRestService } from 'platform-unit2-api/core';
import { AwsFileService } from '../../file-service/aws-file.service';
import { stringToUpperCase } from '@/general/utils/string-to-upper-case';
import store from '@/core/store';
import { User } from 'platform-unit2-api/users';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import getLast from '@/general/utils/get-last';
import { Pipeline, PipelineStatus, PipelineStatusLevel } from 'platform-unit2-api/pipelines';
import { DataTableFilterEvent, DataTableFilterMetaData } from 'primevue/datatable';
import { ChannelFilter } from '@/platforms/retailer/modules/export/ts/interfaces/channel-filter.interface';
import { FilterMatchMode } from 'primevue/api';
import { StatusSeverity } from '@/general/ui/components/status-chip.vue';
import { ActionStatusSeverityEnum } from 'platform-unit2-api';
import { Platform } from '@/core/router/route.factory';

export class ImportService extends BaseViewService<
  ImportRestService,
  ImportPipeline,
  CreateImportPipeline,
  RestartImportRequest
> {
  private _awsFileService: AwsFileService = new AwsFileService();
  uploading = false;
  resolving = false;
  _uploadApi: UploadsRestService = new UploadsRestService();
  _coreApi: CoreRestService = new CoreRestService();
  _importApi: ImportRestService = new ImportRestService();
  timer: ReturnType<typeof setInterval> | undefined;
  multipleVariants: boolean;
  disableNextButtonUploadPage = false;
  public filters = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'module.name': { value: null, matchMode: FilterMatchMode.EQUALS },
  };
  filterParams: ImportFilters = {};

  constructor(ts?: TranslationService) {
    const currentUser: User | undefined = store.getters['users/currentUser'];
    let currentSpace: ClientTypeEnum =
      currentUser?.workspace?.workspace_type?.type ?? ClientTypeEnum.SUPPLIER;
    if (currentSpace.toString().toLowerCase() === 'dms') {
      currentSpace = ClientTypeEnum.SUPPLIER;
    }

    super({
      Api: ImportRestService,
      fetchAllFunction: 'getAll',
      ts: ts ?? new TranslationService(currentSpace.toLowerCase() as Platform, 'imports'),
      confirmPopUpGroup: 'import-dialog',
      createRouteName: 'new-import-settings',
      updateRouteName: 'update-import-optional',
      overviewRouteName: 'import',
    });

    this._fetchVariables.limit = 15;
    this.assignTimer();
    this.refetch = this.fetchAll;
    this.multipleVariants = Boolean(this.partialObject.settings?.variant_column);
  }

  public get partialObject(): Partial<ImportPipeline> {
    if (this._currentObject == null) {
      this._currentObject = {
        settings: {
          overwrite_data: true,
        },
      };
    }

    if (this._currentObject.files == null) {
      this._currentObject.files = [];
    }

    return this._currentObject;
  }

  get childRoutes() {
    return [
      {
        label: this._ts.tGlobal('settings'),
        to: {
          name: `${this.isCreating ? 'new' : 'update'}-import-settings`,
          params: { id: this.partialObject.id },
        },
      },
      {
        label: this._ts.tGlobal('upload'),
        to: {
          name: `${this.isCreating ? 'new' : 'update'}-import-upload`,
          params: { id: this.partialObject.id },
        },
      },
      {
        label: stringToUpperCase(this._ts.tGlobal('optional')),
        to: {
          name: `${this.isCreating ? 'new' : 'update'}-import-optional`,
          params: { id: this.partialObject.id },
        },
      },
    ];
  }

  get dialogHeader() {
    return this.isCreating ? this._ts.tModule('new.title') : this._ts.tModule('restart.title');
  }

  public get imports() {
    return this._data;
  }

  public get disableSettingsPageButtonCondition() {
    return (
      this.partialObject.module == null ||
      this.partialObject.mapping == null ||
      this.partialObject.locale == null
    );
  }

  public disableUploadPageButtonCondition(files: File[] = []) {
    this.disableNextButtonUploadPage = this.uploading || files.length > 0;
  }

  public get getDisabledButtonsConditions() {
    return [
      {
        pageIndex: 0,
        disableNextStep: this.disableSettingsPageButtonCondition,
      },
      {
        pageIndex: 1,
        disableNextStep: this.disableNextButtonUploadPage,
      },
      {
        pageIndex: 2,
        disablePreviousStep: !this.isCreating,
        disableNextStep:
          this.resolving ||
          (!this.partialObject.settings?.import_assets &&
            !this.partialObject.settings?.import_data) ||
          ((this.partialObject.settings.variant_column == null ||
            this.partialObject.settings.variant_column === '') &&
            this.multipleVariants),
      },
    ];
  }

  public get isCreating(): boolean {
    return (this._route.name?.toString() ?? '').includes('new');
  }

  public get resolveCrudComponentCondition(): boolean {
    this.disableNextButtonUploadPage = false;
    const prevRoute: string = (this._router.options.history.state['back'] as string) ?? '';
    return (
      this.childRoutes.map((child) => child.to.name).includes(this._route.name?.toString() ?? '') &&
      prevRoute === '/import'
    );
  }

  public assignTimer() {
    if (this.timer != null) {
      clearInterval(this.timer as ReturnType<typeof setInterval>);
    }

    this.timer = setInterval(this.pollWithoutLoading.bind(this), 5000);
  }

  public removeSelectedImage(
    index: number,
    files: File[],
    removeFileCallback: (index: number) => void,
  ) {
    removeFileCallback(index);
    this.disableUploadPageButtonCondition(files);
  }

  public pollWithoutLoading() {
    this._restService.getAll(this._fetchVariables, this.filterParams).then((res) => {
      this._data = res.data;
      this.total = res.meta?.total;
    });
  }

  public createBody(): CreateImportPipeline | undefined {
    return {
      locale_id: this.partialObject.locale!.id,
      mapping_id: this.partialObject.mapping!.id,
      module_id: this.partialObject.module!.id,
      settings: {
        dry_run: this.partialObject.settings?.dry_run ?? false,
        import_assets: this.partialObject.settings?.import_assets ?? false,
        import_data: this.partialObject.settings?.import_data ?? false,
        trim_ean: this.partialObject.settings?.trim_ean ?? false,
        update_brand: this.partialObject.settings?.update_brand ?? false,
        update_categories: this.partialObject.settings?.update_categories ?? false,
        update_title: this.partialObject.settings?.update_title ?? false,
        update_variants: this.partialObject.settings?.update_variants ?? false,
        overwrite_data: this.partialObject.settings?.overwrite_data ?? true,
      },
      files: this.partialObject.files ?? [],
    };
  }

  public updateBody(): RestartImportRequest | undefined {
    return {
      id: this.partialObject.id!,
      settings: {
        dry_run: this.partialObject.settings?.dry_run ?? false,
        import_assets: this.partialObject.settings?.import_assets ?? false,
        import_data: this.partialObject.settings?.import_data ?? false,
        trim_ean: this.partialObject.settings?.trim_ean ?? false,
        update_brand: this.partialObject.settings?.update_brand ?? false,
        update_categories: this.partialObject.settings?.update_categories ?? false,
        update_title: this.partialObject.settings?.update_title ?? false,
        update_variants: this.partialObject.settings?.update_variants ?? false,
        overwrite_data: this.partialObject.settings?.overwrite_data ?? true,
      },
    };
  }

  public get validated(): boolean {
    return (
      this.partialObject.module != null &&
      this.partialObject.mapping != null &&
      this.partialObject.locale != null &&
      this.partialObject.settings != null
    );
  }

  public fetchAll(): void {
    this._isLoadingOverview = true;

    this._restService
      .getAll(this._fetchVariables, this.filterParams)
      .then((response) => {
        this._data = response.data;
        this.total = response.meta?.total;
        this.getAllCallback?.();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
        this._data = [];
      })
      .finally(() => {
        this._isLoadingOverview = false;
        this.isInitialized = true;
      });
  }

  private _createUploadRequest(file: File, ref: string): CreateUploadRequest {
    return {
      filename: file.name,
      contenttype: file.type,
      reference: ref,
      resolution_in_pixels_height: 0,
      resolution_in_pixels_width: 0,
    };
  }

  public async uploadFiles(event: FileUploadUploaderEvent) {
    this.uploading = true;
    const files = event.files as File[];

    const uploadPromises = files.map(async (file: File) => {
      const sign = await this._awsFileService.signAndUpload(file);

      if (sign != null) {
        const createUploadRequest = this._createUploadRequest(file, sign);

        this._uploadApi.post(createUploadRequest).then((res) => {
          this.partialObject.files!.push(res.public_url);
        });
      }
    });

    await Promise.all(uploadPromises)
      .then(() => {
        this._toastService.displaySuccessToast(this._ts.tModule('new.filesSuccessfullyUploaded'));
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.tModule('new.filesUploadedFailed'));
      })
      .finally(() => {
        this.uploading = false;
        this.disableUploadPageButtonCondition();
      });
  }

  public resolveCrudComponent(): void {
    if (this.resolveCrudComponentCondition) {
      super.openCrudComponent();
    }

    this.crudComponent
      ? clearInterval(this.timer as ReturnType<typeof setInterval>)
      : this.assignTimer();
  }

  public downloadFiles(files: string[]) {
    files.forEach((file) => {
      window.open(file, '_parent', 'download');
    });
  }

  // TODO fix create and update body with correct data
  public async submit() {
    try {
      this.resolving = true;
      if (!this.isCreating && this.updateBody) {
        await this._importApi.restartImport({ ...this.updateBody() } as RestartImportRequest);
      } else {
        await this._importApi.post(this.createBody() as CreateImportPipeline);
      }

      this._toastService.displaySuccessToast(this._ts.createSuccess());
      this.closeCrudComponent();
      await this.refetch();
    } catch (e) {
      this._toastService.displayErrorToast(this._ts.createFailed());
    } finally {
      this.resolving = false;
    }
  }

  // TODO does it have a separate delete (the /import endpoint)
  public deleteAction(id: number): void {
    this._confirmService.confirmDelete({
      callback: () => {
        this._restService
          .delete(id)
          .then((_) => {
            this._isLoadingOverview = true;
            this._toastService.displaySuccessToast(this._ts.deleteSuccess());
            this.refetch();
          })
          .catch(() => {
            this._toastService.displayErrorToast(this._ts.deleteFailed());
          })
          .finally(() => {
            this._isLoadingOverview = false;
          });
      },
      group: 'import-dialog',
    });
  }

  public goToProducts(id?: number) {
    if (id == null) {
      return;
    }

    this._router.push({
      name: 'products',
      query: { pipelineId: id },
    });
  }

  /*
  / Get last item with level different than info
  / If there are no other levels, return last element with level 'info'
  */
  public getCurrentStatus(pipeline?: ImportPipeline): PipelineStatus | undefined {
    const sortedStatuses = (pipeline?.statuses ?? []).sort((a, b) => a.id - b.id);
    const filteredStatuses = sortedStatuses.filter(
      (item) => item.level !== 'info' && item.level != null,
    );

    return getLast(filteredStatuses.length !== 0 ? filteredStatuses : sortedStatuses);
  }

  public applyFilter(event: DataTableFilterEvent) {
    this.filterParams = {
      ...this.filterParams,
      module_ids: (event.filters['module.name'] as DataTableFilterMetaData).value?.map(
        (module: ChannelFilter) => module.id,
      ),
    };

    this.refetch();
  }

  /**
   * Toggles the visibility of the pipeline details modal
   */
  public isDetailsModalVisible = false;
  public pipelineDetails?: ImportPipeline;

  public togglePipelineDetails(visible: boolean, pipeline?: ImportPipeline) {
    this.isDetailsModalVisible = visible;
    if (pipeline != null) {
      this.pipelineDetails = pipeline;
    }
  }

  public getImportStatus(pipeline: Pipeline): { label: string; severity: StatusSeverity } {
    const pipelineHasErrors = pipeline.statuses?.some((status) => status.level === 'error');

    return {
      label: pipelineHasErrors ? 'Failed' : PipelineStatusLevel[pipeline.current_status],
      severity: pipelineHasErrors
        ? ActionStatusSeverityEnum.FAILED
        : ActionStatusSeverityEnum[
            PipelineStatusLevel[pipeline.current_status] as keyof typeof ActionStatusSeverityEnum
          ],
    };
  }
}
