<script setup lang="ts">
import { inject, ref } from 'vue';
import WrapperField from './wrapper-field.vue';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { TagFieldService } from '@/general/services/attribute-fields/field-services/tag-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
/** Props */
interface Props {
  attributeField: AttributeField<string[] | undefined>;
  productAttributeField: ProductAttributeField<string[] | undefined>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const tagFieldService = ref(
  new TagFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
</script>

<template>
  <WrapperField :attribute-field-service="tagFieldService">
    <pChips
      v-model="tagFieldService.productAttributeFieldValue"
      :placeholder="tagFieldService.ts.tGlobal('placeholder')"
      :disabled="tagFieldService.productAttributeField.locked"
      :pt="{
        container: () => ({
          class: tagFieldService.getErrorTypeClass(),
        }),
      }"
  /></WrapperField>
</template>
