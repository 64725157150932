<script setup lang="ts">
import { Client } from 'platform-unit2-api/clients';
import { UpdateUserRequest, User } from 'platform-unit2-api/users';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { RouterService } from '@/core/router/router.service';
import UserAvatar from 'ui/components/user-avatar.vue';
import Upload from '../../components/upload.vue';
import { authorizationMethods } from 'composables/authorization.plugin';
import HelpAndSupportSidebar from './help-and-support-sidebar.vue';
import { AdminModeToggleService } from '@/general/services/admin-mode-toggle.service';

const props = defineProps<{
  currentUser: User;
  isCollapsed: boolean;
}>();

const workspaces = computed(() => {
  return (props.currentUser?.clients ?? [])
    .map((item: Client) => item)
    .sort((a: Client, b: Client) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
});

const store = useStore();
const toastService = ToastService.getInstance();
const ts = new TranslationService('general', 'components');
const helpAndSupportVisible = ref(false);
const helpAndSupportToVisible = (value: boolean) => {
  helpAndSupportVisible.value = value;
};

const { hasRole } = authorizationMethods;
const adminModeToggleService = new AdminModeToggleService();

const updateWorkspace = async (id: number) => {
  try {
    await store.dispatch('users/UPDATE_WORKSPACE', {
      userId: props.currentUser.id,
      workspaceId: id,
    });
    toastService.displaySuccessToast(ts.tModule('applicationHeader.switchSuccessful'));
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('applicationHeader.switchFailed'));
  } finally {
    await removeFiltersFromStorage();
    await RouterService.getInstance().go({ name: 'default' });
  }
};

const removeFiltersFromStorage = async () => {
  await store.dispatch('products/RESET_FILTERS');
  await store.dispatch('products/SAVE_SEARCH', '');
};

//Switch to admin mode
const isAdminModeOn = computed((): boolean => props.currentUser?.admin_mode ?? false);

const switchToAdminMode = async (adminMode: boolean) => {
  if (isAdminModeOn.value === adminMode) return;
  try {
    const updatedUser = {
      admin_mode: adminMode,
    } as UpdateUserRequest;

    await store.dispatch('users/UPDATE_USER', {
      id: store.getters['users/currentUser'].id,
      updatedUser,
    });
    toastService.displaySuccessToast(ts.tModule('userMenu.switchSuccessful'));
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('userMenu.switchFailed'));
  } finally {
    adminModeToggleService.toggleAdminMode(adminMode);
  }
};

/** Search in workspaces */
const searchWord = ref('');
const filteredWorkspaces = computed(() => {
  return workspaces.value.filter((workspace) =>
    workspace.name.toLocaleLowerCase().includes(searchWord.value.toLocaleLowerCase()),
  );
});
</script>
<template>
  <!-- Workspace -->
  <div v-if="isCollapsed" class="profile">
    <!-- Workspace thumbnail -->
    <UserAvatar
      class="flex-shrink-0 mr-3"
      :url="
        currentUser?.workspace?.thumbnail?.public_thumbnail_url ||
        currentUser?.workspace?.thumbnail?.public_url
      "
      :username="currentUser?.workspace?.name"
      style="width: 32px; height: 32px"
      shape="square"
    />
    <!-- Workspace name -->
    <div>
      <strong class="workspace-name">{{ currentUser?.workspace?.name }} </strong>
    </div>
  </div>

  <!-- Separator line -->
  <div v-if="isCollapsed" class="border-top"></div>

  <!-- Admin mode switch -->
  <div v-if="hasRole({ name: 'admin' })" class="admin-mode">
    <span>Admin mode</span>
    <div class="switch-box">
      <span :class="{ active: isAdminModeOn }" @click="switchToAdminMode(true)">On</span>
      <span :class="{ active: !isAdminModeOn }" @click="switchToAdminMode(false)">Off</span>
    </div>
  </div>

  <!-- Workspaces -->
  <span v-if="!currentUser.admin_mode" class="workspace-title">Your workspaces</span>
  <div class="menu-items">
    <!-- Search box -->
    <div v-if="workspaces.length > 5 && !currentUser.admin_mode" class="search-workspace">
      <pIconField icon-position="left" class="w-full">
        <pInputIcon class="pi pi-search" />
        <pInputText v-model="searchWord" type="text" placeholder="Search" class="w-full" />
      </pIconField>
    </div>
    <ul v-if="!currentUser.admin_mode" class="workspaces">
      <li
        v-for="workspace in filteredWorkspaces"
        :key="workspace.id"
        :class="{ exactActiveItem: currentUser?.workspace?.id === workspace.id }"
        @click="updateWorkspace(workspace.id)"
      >
        <div class="logo">
          <Upload :upload="workspace.thumbnail" />
        </div>
        <span class="overflow-hidden text-overflow-ellipsis" style="max-width: 80%">
          {{ workspace.name }}
        </span>
      </li>
    </ul>
  </div>
  <HelpAndSupportSidebar :visible="helpAndSupportVisible" @hide="helpAndSupportToVisible(false)" />
</template>
<style lang="scss" scoped>
.profile {
  position: relative;
  width: auto;
  box-sizing: content-box;
  padding: 16px 8px 8px 8px;
  margin: 0 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.admin-mode {
  background-color: $secondary-button-hover-bg;
  border: 1px solid $gray-border-color;
  padding: 12px;
  margin: 16px;
  border-radius: 3px;
  color: $link-color;

  .switch-box {
    display: flex;
    margin-top: 8px;
    background: $white;
    padding: 6px;
    border: 1px solid $separate-border-color;
    border-radius: 3px;
    position: relative;
    isolation: isolate;

    & > span {
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      padding: 6px 0;
      cursor: pointer;
      transition: all $animation-duration ease;
      border-radius: 3px;

      &.active {
        color: $white;
        background-color: $primary-color;
      }
    }
  }
}
.workspace-title {
  display: block;
  font-size: 10px;
  margin: 16px 16px 8px 16px;
  color: #4d7ba8;
}
.menu-items {
  padding-bottom: 8px;

  li {
    color: $link-color;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 14px;
    border: 4px solid transparent;
    transition: all $animation-duration ease;
    cursor: pointer;

    &.exactActiveItem {
      border-left-color: #377ef2;
      background-color: #e0f0ff;
      color: #172533;
    }

    &:hover:not(.exact-active-item) {
      background-color: $link-hover-bg;
    }

    i {
      color: $icon-inactive;
    }

    span {
      padding-left: 10px;
      max-width: 200px;
      transition: all $animation-duration ease;
      display: block;
    }

    a {
      display: flex;
      align-items: center;
      color: $link-color;
      width: 100%;
    }
  }

  .logo {
    width: 31px;
    border: 1px solid $gray-border-color;
    border-radius: 3px;
    padding: 4px;

    img {
      object-fit: cover;
      border-radius: 3px;
    }
  }
}

.workspaces {
  height: 120px;
  overflow-y: auto;
}

.search-workspace {
  padding: 8px 16px;
}
.border-top {
  border-top: 1px solid #dce5ef;
  margin-top: auto;
  margin: 8px 16px;
}

.workspace-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: initial;
}
</style>
