<script setup lang="ts">
import { useCopyText } from '@/general/composables/useCopyText/useCopyText';
import { PipelineStatus } from 'platform-unit2-api/pipelines';
import { ref } from 'vue';

interface Props {
  pipelineStatus: PipelineStatus;
}

defineProps<Props>();

const op = ref();
const toggle = (event: Event) => {
  op.value.toggle(event);
};

const { copyToClipboard, copySuccess } = useCopyText();
</script>
<template>
  {{ pipelineStatus?.text }}
  <i
    v-if="pipelineStatus.message"
    class="cursor-pointer mdi mdi-help-circle-outline"
    @click="toggle"
  />
  <pOverlayPanel
    ref="op"
    :pt="{
      root: {
        style: { maxWidth: '50rem', background: '#222', color: '#eee' },
      },
    }"
  >
    <div class="flex flex-column">
      <i
        :class="copySuccess ? 'mdi-check' : 'mdi-content-copy'"
        class="cursor-pointer mb-3 mdi ml-auto"
        @click="copyToClipboard(pipelineStatus.message)"
      />
      {{ pipelineStatus.message }}
    </div>
  </pOverlayPanel>
</template>
