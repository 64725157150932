<script setup lang="ts">
import { MenuItem } from 'primevue/menuitem';
import TabsManager from '@/general/ui/layout/tabs-manager.vue';
import Breadcrumbs from '@/general/ui/layout/components/breadcrumbs.vue';
import { RouteRecordRaw, useRoute } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Ref, onMounted, onUpdated, ref, watch, computed } from 'vue';
import { authorizationMethods } from 'composables/authorization.plugin';
import { FeaturePermission } from 'platform-unit2-api/roles';
import HelpAndSupportSidebar from '@/general/ui/layout/components/help-and-support-sidebar.vue';
import AccountMenu from '@/general/ui/layout/components/account-menu.vue';
import { Platform } from '@/core/router/route.factory';

const { hasAllPermissions } = authorizationMethods;

const props = withDefaults(
  defineProps<{
    title?: string;
    tabs?: MenuItem[];
    creatable?: boolean;
    platform?: Platform;
    module?: string;
    createButtonText?: string;
  }>(),
  {
    title: undefined,
    tabs: undefined,
    creatable: true,
    platform: undefined,
    module: undefined,
    createButtonText: undefined,
  },
);

const ts: Ref<TranslationService | undefined> = ref();
const createRoute: Ref<RouteRecordRaw | undefined> = ref();

const currentRoute = useRoute();

const helpAndSupportVisible = ref(false);
const helpAndSupportToVisible = (value: boolean) => {
  helpAndSupportVisible.value = value;
};

function findCreateRoute() {
  const route = currentRoute.matched[0]?.children.find(
    (route) => route.path.includes('create') || route.path.includes('new'),
  );

  const rights: FeaturePermission[] = route?.meta?.rights as any;

  if (!rights) {
    return route;
  }

  if (hasAllPermissions(...rights)) {
    return route;
  }

  return;
}

function resolveTranslations() {
  if (props.platform != null && props.module != null && ts.value) {
    ts.value.platform = props.platform!;
    ts.value.module = props.module!;
  } else if (props.platform != null && props.module != null) {
    ts.value = new TranslationService(props.platform!, props.module);
  } else {
    ts.value = undefined;
  }
}

const buttonLabel = computed(() => {
  if (props.createButtonText) {
    return props.createButtonText;
  } else if (ts.value?.createButton) {
    return ts.value?.createButton;
  } else {
    return `Create ${props.title}`;
  }
});

watch(currentRoute, () => {
  createRoute.value = findCreateRoute();
});

onUpdated(() => {
  resolveTranslations();
});

onMounted(() => {
  resolveTranslations();
  createRoute.value = findCreateRoute();
});
</script>
<template>
  <div class="bg-white border-bottom pup-mt-5 pup-px-7">
    <div class="align-items-center flex justify-content-between pup-mb-7">
      <div>
        <slot name="breadcrumbs">
          <Breadcrumbs :page-title="ts ? ts.titlePlural : title" />
        </slot>
      </div>
      <slot name="actions">
        <div class="flex">
          <pButton
            v-tooltip.bottom="{
              value: 'Help and Support',
              class: 'text-sm',
            }"
            text
            plain
            rounded
            class="pup-h-3 pup-mr-4 pup-w-3"
            @click="helpAndSupportToVisible(true)"
            ><template #icon>
              <p-image src="/src/assets/images/pup_ic_question.svg"></p-image>
            </template>
          </pButton>
          <AccountMenu />
          <!-- Sidebar, it is not visible in layout -->
          <HelpAndSupportSidebar
            :visible="helpAndSupportVisible"
            @hide="helpAndSupportToVisible(false)"
          />
        </div>
      </slot>
    </div>
    <slot name="content">
      <h3 class="pb-4" data-testid="breadcrumb-header-title">
        {{ ts ? ts.titlePlural : title }}
      </h3>
    </slot>

    <slot name="tabs">
      <TabsManager :tabs="tabs" />
    </slot>

    <slot name="createButton">
      <router-link
        v-if="createRoute?.name && creatable"
        class="headerButton"
        :to="{ name: createRoute.name }"
      >
        <pButton
          :label="buttonLabel.toUpperCase()"
          rounded
          icon="mdi mdi-plus"
          class="font-bold primary-button"
        />
      </router-link>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
.primary-button {
  font-size: 12px;
  height: 40px;
  & :deep(.p-button-icon) {
    font-size: 20px;
  }
}
.border-bottom {
  border-bottom: 2px solid #e5e5e5;
}

.headerButton {
  position: absolute;
  margin-bottom: -5rem;
  right: 2rem;
  transform: translate(0, -50%);
  width: fit-content;
}

.p-button :deep(.p-button-icon) {
  font-size: 2.5rem;
}
</style>
