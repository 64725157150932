<script setup lang="ts">
import { inject, ref } from 'vue';
import WrapperField from './wrapper-field.vue';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { DateTimeFieldService } from '@/general/services/attribute-fields/field-services/date-time-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
/** Props */
interface Props {
  attributeField: AttributeField<string | undefined>;
  productAttributeField: ProductAttributeField<string | undefined>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const dateTimeFieldService = ref(
  new DateTimeFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
</script>
<template>
  <WrapperField :attribute-field-service="dateTimeFieldService">
    <pCalendar
      v-model="dateTimeFieldService.productAttributeFieldValue"
      :disabled="dateTimeFieldService.productAttributeField.locked"
      :placeholder="dateTimeFieldService.ts.tGlobal('placeholder')"
      :show-time="!!dateTimeFieldService.attributeField.attribute?.options?.enableTime"
      :pt="{
        input: () => ({
          class: dateTimeFieldService.getErrorTypeClass(),
        }),
      }"
      show-button-bar
    />
  </WrapperField>
</template>
