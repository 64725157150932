<script setup lang="ts">
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ChildRoute } from 'platform-unit2-api/core';
import { CreateExport, PageIndexEnum } from '../../ts/products.types';
import { StartExportRequest, ExportTypeEnum, ExportRestService } from 'platform-unit2-api/exports';
import { AdvancedExportSettings } from '../../ts/interfaces/advanced-export-settings.interface';
import { IntegrationField } from 'platform-unit2-api/modules';
import {
  ScheduledPipelinesRestService,
  CreateScheduledPipelineRequest,
} from 'platform-unit2-api/scheduled-pipelines';
import { HeapService } from '@/core/app/ts/services/heap.service';
import { DebugLogger } from '@/core/main/debug.logger';

/** Props */
interface Props {
  isActive: boolean;
  ids: number[];
  inputData?: AdvancedExportSettings;
  childRoutes: ChildRoute[];
}
const props = withDefaults(defineProps<Props>(), {
  isActive: false,
  childRoutes: () => [],
  ids: () => [],
  inputData: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'toTypeSelection', value: boolean): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const scheduledPipelineApi = new ScheduledPipelinesRestService();
const exportPipelineApi = new ExportRestService();
const activeStep = ref(0);
const route = useRoute();
/** Consts */
const store = useStore();
const router = useRouter();
const formObject = ref({} as CreateExport);
const isExporting = ref(false);

const settingPage = 1;
const mailToPage = 3;
const overViewPage = 4;

const step = 1;
const skipStep = 2;

const amountOfStepsForward = (currentPage?: number): number => {
  if (currentPage === settingPage) {
    return formObject.value.isScheduled ? step : skipStep;
  }

  return step;
};

const nextPage = (event: CreateExport) => {
  switch (event.pageIndex) {
    case PageIndexEnum.CHANNEL_VIEW_PAGE:
      formObject.value.selectedModule = event.selectedModule;
      formObject.value.selectedLocale = event.selectedLocale;
      break;
    case PageIndexEnum.SETTING_EXPORT_VIEW_PAGE:
      formObject.value.includeData = event.includeData;
      formObject.value.includeAssets = event.includeAssets;
      formObject.value.changed_products = event.changed_products;
      formObject.value.product_ids = props.ids;
      formObject.value.isScheduled = event.isScheduled;
      break;
    case PageIndexEnum.SCHEDULE_VIEW_PAGE:
      formObject.value.exportName = event.exportName;
      formObject.value.exportCron = event.exportCron;
      formObject.value.ignoreProductStatus = event.ignoreProductStatus;
      break;
    case PageIndexEnum.CONTACT_VIEW_PAGE:
      formObject.value.mailTo = event.mailTo;
      formObject.value.customMessage = event.customMessage;
      break;
    case PageIndexEnum.INFO_EXPORT_VIEW_PAGE:
      break;
  }

  router.push(
    props.childRoutes[
      (event.pageIndex ? event.pageIndex : PageIndexEnum.CHANNEL_VIEW_PAGE) +
        amountOfStepsForward(event.pageIndex)
    ].to,
  );
};

const amountOfStepsBack = (currentPage?: number): number => {
  if (currentPage === overViewPage) {
    return formObject.value.isScheduled ? skipStep : step;
  }

  if (currentPage === mailToPage) {
    return formObject.value.isScheduled ? step : skipStep;
  }

  return step;
};

const prevPage = (event: CreateExport) => {
  if (event.pageIndex === PageIndexEnum.CHANNEL_VIEW_PAGE) {
    emit('toTypeSelection', false);
    return;
  }

  router.push(
    props.childRoutes[
      (event.pageIndex ? event.pageIndex : PageIndexEnum.CHANNEL_VIEW_PAGE) -
        amountOfStepsBack(event.pageIndex)
    ].to,
  );
};

const constructPromptPayload = () => {
  const data: Record<string, string> = {};
  if (
    formObject.value.selectedModule.settings_fields &&
    formObject.value.selectedModule.settings_fields.prompt_fields
  ) {
    formObject.value.selectedModule.settings_fields.prompt_fields.forEach(
      (field: IntegrationField): void => {
        data[field.label.replaceAll(' ', '_').toLowerCase()] = field.value;
      },
    );
    return data;
  }

  return {};
};

const complete = async () => {
  isExporting.value = true;

  //Set schedule
  if (formObject.value.isScheduled) {
    try {
      const createScheduledExport: CreateScheduledPipelineRequest = {
        name: formObject.value.exportName ?? '',
        cron: formObject.value.exportCron ?? '',
        client_id: store.getters['users/currentUser'].workspace?.id ?? 0,
        module_id: formObject.value.selectedModule.id ?? 0,
        is_paused: false,
        product_ids: props.ids ?? [],
        type: 'export',
        locale_id: formObject.value.selectedLocale.id ?? 0,
        mail_to: formObject.value.mailTo ?? [],
        custom_message: formObject.value.customMessage ?? '',
        include_assets: formObject.value.includeAssets ?? false,
        include_data: formObject.value.includeData ?? false,
        multiple_variants: false,
        ignore_product_status: formObject.value.ignoreProductStatus,
      };

      await scheduledPipelineApi.post(createScheduledExport);

      HeapService.track('start_scheduled_export', {
        channel: formObject.value.selectedModule.name,
        language: formObject.value.selectedLocale.value,
        exported_to_email: createScheduledExport.mail_to,
        product_count: props.ids.length,
        products: createScheduledExport.product_ids,
        assets: createScheduledExport.include_assets,
        data: createScheduledExport.include_data,
        created_at: Date.now(),
        request: createScheduledExport,
      });

      toastService.displaySuccessToast(ts.tModule('export_modal.scheduled_successfully'));
    } catch (err: any) {
      DebugLogger.error(err);
      toastService.displayErrorToast(ts.tModule('export_modal.starting_failed'));
      await router.push({ name: 'products' } as RouteLocationRaw);
    } finally {
      emit('hide');
    }
  } else {
    //Start Export
    try {
      const startExport: StartExportRequest = {
        mail_to: formObject.value.mailTo ?? [],
        custom_message: formObject.value.customMessage ?? '',
        module_id: formObject.value.selectedModule.id ?? 0,
        product_ids: props.ids ?? [],
        locale_id: formObject.value.selectedLocale.id ?? 0,
        changed_products: [formObject.value.changed_products],
        include_data: formObject.value.includeData ?? false,
        include_assets: formObject.value.includeAssets ?? false,
        export_type: ExportTypeEnum.MAPPING_EXPORT,
        prompt_fields: formObject.value.selectedModule.settings_fields
          ? constructPromptPayload()
          : {},
        files: undefined,
      };

      await exportPipelineApi.startExport(startExport);

      HeapService.track('start_export', {
        channel: formObject.value.selectedModule.name,
        language: formObject.value.selectedLocale.value,
        exported_to_email: startExport.mail_to,
        product_count: startExport.product_ids.length,
        products: startExport.product_ids,
        assets: startExport.include_assets,
        data: startExport.include_data,
        created_at: Date.now(),
        request: startExport,
      });

      toastService.displaySuccessToast(ts.tModule('export_modal.started_successfully'));
    } catch (err: any) {
      DebugLogger.error(err);

      toastService.displayErrorToast(ts.tModule('export_modal.starting_failed'));
      await router.push({ name: 'products' } as RouteLocationRaw);
    } finally {
      emit('hide');
    }
  }
};

// Lifecycle hooks
watch(
  () => route.name,
  () => {
    // Child routes should pass the name of the route, not its path
    activeStep.value = props.childRoutes.findIndex(
      (childRoute) => ((childRoute.to as { name: string }).name ?? childRoute.to) === route.name,
    );

    if (activeStep.value === -1) {
      activeStep.value = 0;
    }
  },
);
</script>
<template>
  <pDialog
    :visible="isActive"
    :header="ts.tModule('export_modal.title')"
    :style="{ width: '60vw' }"
    :modal="true"
    position="center"
    closable
    :close-on-escape="false"
    :draggable="true"
    content-style="overflow-y: visible;  padding:0; border-bottom-right-radius: 6px; border-bottom-left-radius: 6px;"
    :maximizable="false"
    @update:visible="emit('hide')"
  >
    <pSteps :model="childRoutes" readonly class="mb-3" :active-step="activeStep" />
    <router-view
      v-slot="{ Component }"
      :key="$route.fullPath"
      :form-data="formObject"
      @prev-page="prevPage($event)"
      @next-page="nextPage($event)"
      @complete="complete()"
      @cancel-export="emit('hide')"
    >
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </pDialog>
</template>
