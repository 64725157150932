<script setup lang="ts">
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';

import { ref, computed, inject } from 'vue';
import { ProductCompact } from 'platform-unit2-api/products';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';

/**Props */
interface Props {
  isSelected: boolean;
  variant?: ProductCompact;
}

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
  variant: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'deleted'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const confirmService = new ConfirmService();
const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Consts */
const store = useStore();

const isMasterData = computed(() => {
  return props.variant?.variant_name == null;
});

const channelThumbnail = computed(() => {
  return props.variant?.module?.retailer?.thumbnail?.public_thumbnail_url || '';
});

const confrimVariantDelete = () => {
  confirmService.confirmDelete({
    callback: () => deleteVariant(),
    group: 'top-level',
    message: ts.deleteConfirm(props.variant?.variant_name || ''),
  });
};

const deleteVariant = async () => {
  if (isMasterData.value) {
    return;
  }

  try {
    await store.dispatch('products/DELETE_VARIANT', store.getters['products/currentProduct'].id);

    emit('deleted');
    toastService.displaySuccessToast(ts.deleteSuccess());

    await store.dispatch(
      'products/GET_PRODUCT_VARIANTS',
      store.getters['products/currentProduct'].variant_uuid,
    );
  } catch (ex) {
    toastService.displayErrorToast(ts.deleteFailed());
  } finally {
    // isDeleting.value = false;
  }
};

const menu = ref();
const items = ref([
  {
    visible: () => !isMasterData.value,
    label: ts.tModule('remove_variant'),
    icon: 'mdi mdi-delete-outline',
    command: () => {
      confrimVariantDelete();
    },
  },
]);

const toggleVariantMenu = (event: PointerEvent) => {
  menu.value.toggle(event);
};
</script>

<template>
  <div
    v-if="isSelected && productAttributeFieldsService.isSaveButtonDisabled()"
    v-badge.danger="productAttributeFieldsService.errorCount"
    class="align-items-center border-2 border-200 border-round cursor-pointer h-4rem inline-flex mr-3 mt-2 px-2"
    :class="{
      'bg-primary text-white border-primary': isSelected,
      'border-0': isMasterData,
    }"
  >
    <p class="mx-2">
      {{ variant?.variant_name ? variant.variant_name : ts.tGlobal('master_data') }}
    </p>
    <p class="mr-2">{{ variant?.completeness || 0 }}%</p>
    <img
      v-if="!isMasterData && variant?.module"
      class="border-circle"
      :src="channelThumbnail"
      style="width: 2.5rem"
    />
    <p-button
      v-if="!isMasterData"
      aria-haspopup="true"
      aria-controls="variant_menu"
      :class="{
        'text-white': isSelected,
      }"
      :disabled="!isSelected"
      icon="mdi mdi-dots-vertical"
      text
      @click="(event: PointerEvent) => toggleVariantMenu(event)"
    />
    <p-menu id="variant_menu" ref="menu" :model="items" :popup="true" />
  </div>
  <div
    v-else
    class="align-items-center border-2 border-200 border-round cursor-pointer h-4rem inline-flex mr-3 mt-2 px-2"
    :class="{
      'bg-primary text-white border-primary': isSelected,
      'border-0': isMasterData,
    }"
  >
    <p class="mx-2">
      {{ variant?.variant_name ? variant.variant_name : ts.tGlobal('master_data') }}
    </p>
    <p class="mr-2">{{ variant?.completeness || 0 }}%</p>
    <img
      v-if="!isMasterData && variant?.module"
      class="border-circle"
      :src="channelThumbnail"
      style="width: 2.5rem"
    />
    <p-button
      v-if="!isMasterData"
      aria-haspopup="true"
      aria-controls="variant_menu"
      :class="{
        'text-white': isSelected,
      }"
      :disabled="!isSelected"
      icon="mdi mdi-dots-vertical"
      text
      @click="(event: PointerEvent) => toggleVariantMenu(event)"
    />
    <p-menu id="variant_menu" ref="menu" :model="items" :popup="true" />
  </div>
</template>
