export class HeapService {
  private static _instance: HeapService;

  private static get _heap() {
    return (window as any).heap;
  }

  constructor() {
    if (HeapService._instance) {
      return HeapService._instance;
    }

    return this;
  }

  public init() {
    //not checking on env because staging has 'production'
    const isProduction = location.hostname === 'retail.productsup.com';
    const isDevelopment = location.hostname === 'dev.worldofcontent.com';

    if (isProduction || isDevelopment) {
      HeapService._heap.load(import.meta.env.VITE_APP_HEAP_TOKEN, {
        disableTextCapture: true,
      });
    }
  }

  public static getInstance(): HeapService {
    if (!HeapService._instance) {
      HeapService._instance = new HeapService();
    }

    return HeapService._instance;
  }

  public static track(name: string, properties: any = {}) {
    HeapService._heap.track(name, properties);
  }

  public static identify(id: string) {
    HeapService._heap.identify(id);
  }

  public static addUserProperties(properties: any) {
    HeapService._heap.addUserProperties(properties);
  }
}
