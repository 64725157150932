<script setup lang="ts">
import AdvancedSearch from 'retailer/modules/search/components/advanced-search.vue';
import Upload from '@/general/ui/components/upload.vue';
import { computed, onMounted, ref, watch, onUnmounted, nextTick, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import usePagination from 'composables/usePagination/pagination';
import useSortable from 'composables/sortable';
import useSearchable from 'composables/searchable';
import useCheckable from 'composables/checkable';
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import StatusSelect from '@/general/ui/components/selects/product-status-select.vue';
import VariantSelect from '@/general/ui/components/selects/variant-select.vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import { RouteLocationRaw, RouteParamsRaw, useRoute, useRouter } from 'vue-router';
import { FilterMatchMode } from 'primevue/api';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ProductCalendarQuery, ProductUpload, TableColumn } from '../products.types';
import { Product } from 'platform-unit2-api/products';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import EmptyState from '@/general/ui/components/empty-state.vue';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import TableSkeleton from '@/general/ui/components/skeletons/table-skeleton.vue';
import { DataTableRowClickEvent } from 'primevue/datatable';
import StatusChip from '@/general/ui/components/status-chip.vue';
import { ActionStatusSeverityEnum } from 'platform-unit2-api/action-statuses';
import CategorySelect from '@/general/ui/components/selects/category-select.vue';
import ClientSelect from '@/general/ui/components/selects/client-select.vue';
import BrandSelect from '@/general/ui/components/selects/brand-select.vue';
import { formatDate } from '@/general/utils/format-date';

/** Props */
interface Props {
  productIds?: number[];
  excludedProductsIds: number[];
  module?: string;
  selectedProducts?: Product[];
  attachingMedia?: boolean;
  refreshProducts: boolean;
  loadData: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  productIds: () => [],
  excludedProductsIds: () => [],
  selectedProducts: () => [],
  attachingMedia: false,
  refreshProducts: false,
  loadData: false,
  module: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:checkedRows', checkedRows: Product[]): void;
  (e: 'reset-refresh'): void;
  (e: 'create-product'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'products');

/** Consts */
const store = useStore();
const router = useRouter();
const route = useRoute();
const { page, perPage, onPageChange: $onPageChange, onRowChange: $onRowChange } = usePagination();
const { sort, onSortChange: $onSortChange } = useSortable();
const { query, onSearch: $onSearch } = useSearchable();
const { checkedRows, checkedIds } = useCheckable();
const selectedProductIds = ref<number[] | undefined>([]);
const table = ref();
const loading = ref(true);
const checkAll = ref(false);
const productQueryIds = ref<number[]>([]);
const pipelineId = ref<number | undefined>();
const productCalendarQuery = ref<ProductCalendarQuery>();
const gtins = ref('');
const invalidGtins = ref<string[]>([]);
const validGtins = ref<string[]>([]);
const onlyshowSelectedProducts = ref(false);
const products = ref<Product[]>([]);
const missingGtins = ref<string[]>([]);
const total = ref(0);

const resetFilters = (): Record<
  string,
  { value: Record<'id' | 'name', string>[]; matchMode?: string }
> => {
  return {
    variant_name: { value: [], matchMode: FilterMatchMode.STARTS_WITH },
    shared_retailer: { value: [], matchMode: FilterMatchMode.STARTS_WITH },
    datamodel_id: { value: [], matchMode: FilterMatchMode.STARTS_WITH },
    owner_id: { value: [], matchMode: FilterMatchMode.STARTS_WITH },
    brand_id: { value: [], matchMode: FilterMatchMode.STARTS_WITH },
    category: { value: [], matchMode: FilterMatchMode.STARTS_WITH },
    product_status_id: { value: [], matchMode: FilterMatchMode.STARTS_WITH },
    module_id: { value: [], matchMode: FilterMatchMode.STARTS_WITH },
    images_included: { value: [], matchMode: FilterMatchMode.STARTS_WITH },
    id: { value: [] },
  };
};

const filters = ref<Record<string, { value: Record<'id' | 'name', string>[]; matchMode?: string }>>(
  resetFilters(),
);

const isRetailer = computed(
  () =>
    store.getters['users/currentUser'].workspace?.workspace_type?.type === ClientTypeEnum.RETAILER,
);

const stopPropagation = (e: Event) => {
  e.stopPropagation();
};

const stopClickEventOnSelectionColumns = () => {
  const checkboxes = document.querySelectorAll('.p-selection-column');
  checkboxes.forEach((el) => el?.addEventListener('click', (e) => e.stopPropagation()));
};

const onToggle = async (checkedColumns: TableColumn[]) => {
  const cols = store.getters['products/columns'].map((i: TableColumn) => {
    if (checkedColumns.some((k: TableColumn) => k.field == i.field)) {
      i.selected = true;
      return i;
    }

    i.selected = false;
    return i;
  });

  await store.dispatch('products/SET_COLUMNS', cols);
};

const initialized = computed(() => {
  return store.getters['products/initialized'];
});

const loadAsyncData = async (): Promise<void> => {
  loading.value = true;
  try {
    const response = await store.dispatch('products/GET_PRODUCTS', {
      pagination: {
        query: query.value,
        page: page.value,
        limit: perPage.value,
        sortBy: sort.value,
      },
      pipelineId: pipelineId.value,
      excludeIds: props.excludedProductsIds,
      calendarQueryDate: productCalendarQuery.value,
    });

    products.value = response.data;
    total.value = response.meta.total;
    await nextTick();
    stopClickEventOnSelectionColumns();
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed(ts.tModule('snackbars.loadingFailed')));
  } finally {
    loading.value = false;
    checkAll.value = products.value.every((product) => checkedIds.value.includes(product.id));
    emit('reset-refresh');
  }
};

const refresh = async () => {
  checkedRows.value = [];
  await loadAsyncData();
};

const loadAsyncMissingGtins = async (): Promise<void> => {
  try {
    missingGtins.value = (
      await store.dispatch('products/GET_MISSING_GTINS', {
        productIds: productQueryIds.value.length > 0 ? productQueryIds.value : null,
      })
    ).missing;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed(ts.tGlobal('data')));
  }
};

const updatePagination = () => {
  store.dispatch('products/UPDATE_PAGINATION', {
    page: page.value,
    limit: perPage.value,
    query: query.value,
    sortBy: sort.value,
  });
};

const showDetails = async (product: Product) => {
  router.push({
    name: 'product-attributes',
    params: {
      id: product.id,
    } as RouteParamsRaw,
  } as RouteLocationRaw);
};

const onPageChange = async (event: any) => {
  $onPageChange(event.page + 1);
  $onRowChange(event.rows);
  updatePagination();
  await loadAsyncData();
};

const onSortChange = async (event: any) => {
  $onSortChange((event.sortOrder < 0 ? '-' : '') + event.sortField);
  updatePagination();
  await loadAsyncData();
};

const onSearch = (query: string) => {
  store.dispatch('products/SAVE_SEARCH', query);
  $onSearch(query);
};

const applyFilter = async (refresh = true) => {
  await store.dispatch('products/UPDATE_VARIANT_FILTER', filters.value.variant_name.value);
  await store.dispatch('products/UPDATE_OWNER_FILTER', filters.value.owner_id.value);
  await store.dispatch('products/UPDATE_DATAMODEL_FILTER', filters.value.datamodel_id.value);
  await store.dispatch('products/UPDATE_BRAND_FILTER', filters.value.brand_id.value);
  await store.dispatch('products/UPDATE_STATUS_FILTER', filters.value.product_status_id.value);
  await store.dispatch('products/UPDATE_CATEGORY_FILTER', filters.value.category.value);
  await store.dispatch('products/UPDATE_ID_FILTER', productQueryIds.value ?? []);
  await store.dispatch('products/UPDATE_MODULE_FILTER', filters.value.module_id.value);

  if (refresh) await loadAsyncData();
};

const applyGtinFilter = async (gtins: string) => {
  const gtinsArray = gtins.split('\n');
  validGtins.value = gtinsArray.filter(
    (gtin) => gtin.length == 8 || gtin.length == 12 || gtin.length == 13 || gtin.length == 14,
  );
  invalidGtins.value = gtinsArray.filter((gtin) => !validGtins.value.includes(gtin));
  await store.dispatch('products/UPDATE_GTIN_FILTER', validGtins.value);
  await loadAsyncData();
  await loadAsyncMissingGtins();
};

const loadFilters = async (refresh = true) => {
  gtins.value = store.getters['products/gtinFilter'].join('\n');
  filters.value.variant_name.value = store.getters['products/variantFilter'];
  filters.value.datamodel_id.value = store.getters['products/dataModelFilter'];
  filters.value.owner_id.value = store.getters['products/ownerFilter'];
  filters.value.brand_id.value = store.getters['products/brandFilter'];
  filters.value.product_status_id.value = store.getters['products/statusFilter'];
  filters.value.category.value = store.getters['products/categoryFilter'];
  filters.value.id.value = store.getters['products/idFilter'];
  filters.value.module_id.value = store.getters['products/moduleFilter'];
  filters.value.images_included.value = store.getters['products/imagesIncluded'];
  query.value = store.getters['products/getSearch'];
  missingGtins.value = [];
  if (refresh) await loadAsyncData();
};

const toggleCheckAll = () => {
  const productIds = products.value.map((product) => product.id);
  if (checkAll.value) {
    checkedRows.value = [
      ...checkedRows.value,
      ...products.value.filter((product) => !checkedIds.value.includes(product.id)),
    ];
  } else {
    checkedRows.value = checkedRows.value.filter((row: Product) => !productIds.includes(row.id));
  }
};

const onRowClick = (event: DataTableRowClickEvent) => {
  const eventTarget = event.originalEvent.target as Element;
  if (
    eventTarget.classList.contains('p-image-preview-indicator') ||
    eventTarget.classList.contains('p-image-preview-icon') ||
    eventTarget.firstElementChild?.classList.contains('p-checkbox') ||
    props.module === 'module'
  ) {
    event.originalEvent.preventDefault();
  } else {
    showDetails(event.data);
  }
};

onBeforeMount(async () => {
  // Set default sorting
  sort.value = store.getters['products/overviewPagination'].sortBy
    ? store.getters['products/overviewPagination'].sortBy
    : 'display_name';
  if (route.query.pipelineId != null) {
    pipelineId.value = parseInt(route.query.pipelineId.toString());
  } else if (route.query.date != null && route.query.type != null) {
    productCalendarQuery.value = {
      date: new Date(route.query.date.toString()).toISOString(),
      type: route.query.type.toString(),
    };
  } else {
    productQueryIds.value = props.productIds ?? [];
  }

  router.replace({ query: undefined });

  if (productQueryIds.value.length > 0) {
    store.dispatch('products/RESET_FILTERS');
  }

  if (route.query.productsIds?.length !== 0) {
    selectedProductIds.value = route.query.productsIds
      ?.toString()
      .split(',')
      .map((id) => parseInt(id));
  }

  await loadFilters(false);
  await updatePagination();

  nextTick(() => {
    table.value?.initSort();
  });
  router.replace({ query: undefined });
});

onMounted(async () => {
  props.module === 'media' && (perPage.value = 5);
  filters.value.product_status_id.value = await store.dispatch('products/GET_ALL_STATUSES');

  if (store.getters['users/currentUser'].preferences?.default_archived_not_visible) {
    filters.value.product_status_id.value = filters.value.product_status_id.value.filter?.(
      (item: any) => item.label !== 'Archived',
    );
  }

  watch(
    () => props.loadData,
    (value) => {
      loading.value = value;
    },
  );

  if (
    store.getters['users/currentUser'].preferences?.default_master_data_filter &&
    !initialized.value &&
    store.getters['users/currentUser'].workspace?.workspace_type?.type !== ClientTypeEnum.RETAILER
  ) {
    filters.value.variant_name.value = [{ id: 'Master Data', name: 'Master Data' }];
    await store.dispatch('products/UPDATE_INITIALIZED', true);
  }

  await applyFilter(true);
  props.selectedProducts && (checkedRows.value = props.selectedProducts);
});

onUnmounted(() => {
  filters.value = resetFilters();
});

watch(
  () => store.getters['users/currentUser'],
  async () => {
    await loadFilters(false);

    if (store.getters['users/currentUser'].preferences?.default_master_data_filter) {
      filters.value.variant_name.value = [{ id: 'Master Data', name: 'Master Data' }];
      await applyFilter(false);
    }

    await loadAsyncData();
  },
);

watch(
  () => props.refreshProducts,
  () => {
    props.refreshProducts && refresh();
  },
);

watch(
  () => checkedRows.value,
  () => {
    emit('update:checkedRows', checkedRows.value);
    checkAll.value = products.value.every((product) => checkedIds.value.includes(product.id));
  },
);

watch(
  () => query.value,
  async () => {
    page.value = 1;
    onlyshowSelectedProducts.value = false;
    updatePagination();
    await loadAsyncData();
  },
);

const getMaxLength = computed((): number => {
  let maxLength = 0;
  products.value.forEach((product: any) => {
    maxLength =
      product.images_included?.length > maxLength ? product.images_included?.length : maxLength;
  });
  return maxLength;
});

/** Columns for filters */
const columns = computed(() => {
  const columns = store.getters['products/columns'];
  if (products.value.length === 0) {
    return [];
  }

  return columns
    .filter((column: any) => {
      return column.visible && column.type !== 'shared_retailer' && column.type !== 'datamodel';
    })
    .map((col: any) => {
      return col.type === 'images_included'
        ? { ...col, ...{ size: getMaxLength.value * 80 + 'px' } }
        : col;
    });
});
</script>
<template>
  <pDataTable
    v-model:selection="checkedRows"
    v-model:filters="filters"
    :global-filter-fields="[
      'variant_name',
      'gtin',
      'owner_id',
      'datamodel_id',
      'brand_id',
      'product_status_id',
      'gtins',
      'module_id',
    ]"
    :value="onlyshowSelectedProducts ? checkedRows : products"
    responsive-layout="scroll"
    scrollable
    scroll-height="flex"
    removable-sort
    :loading="loading || attachingMedia"
    filter-display="menu"
    :row-hover="true"
    :lazy="true"
    :first="(page - 1) * perPage"
    data-key="id"
    class="h-full"
    @row-click="onRowClick($event)"
    @page="onPageChange($event)"
    @sort="onSortChange($event)"
    @filter="applyFilter($event)"
  >
    <template #loading>
      <TableSkeleton class="w-full" />
    </template>
    <template #header>
      <div class="align-items-center flex">
        <pMultiselect
          :model-value="columns.filter((item: TableColumn) => item.selected)"
          :options="columns"
          option-label="header"
          placeholder="Select Columns"
          class="mr-3"
          style="width: 20em"
          @update:model-value="onToggle"
        />
        <advanced-search
          class="w-full"
          :columns="columns"
          :clear-only-show-selected-products="!onlyshowSelectedProducts"
          @update:model-value="onSearch"
          @refresh:filters="loadFilters"
          @gtin-filter="applyGtinFilter"
          @show-only-selected-products="($event) => (onlyshowSelectedProducts = $event)"
        />
      </div>
      <transition-group name="p-message" tag="div">
        <p-message v-if="invalidGtins.length" class="w-full" severity="error" :closable="true"
          ><div class="mb-1">
            <b>
              {{
                ts.tModule('products.invalid_gtins', {
                  params: { amount: invalidGtins.length },
                })
              }}</b
            >
          </div>
          <ul class="overflow-y-auto w-full" style="max-height: 10rem">
            <li v-for="(gtin, index) in invalidGtins" :key="gtin">
              {{ gtin
              }}{{ missingGtins.length > 1 && missingGtins.length !== index + 1 ? ',' : '' }}
            </li>
          </ul>
        </p-message>
        <p-message v-if="missingGtins.length" class="w-full" severity="warn" :closable="true"
          ><div class="mb-1">
            <b>
              {{
                ts.tModule('products.undifined_gtins', {
                  params: { amount: missingGtins.length },
                })
              }}</b
            >
          </div>
          <ul class="overflow-y-auto w-full" style="max-height: 10rem">
            <li v-for="(gtin, index) in missingGtins" :key="gtin">
              {{ gtin
              }}{{ missingGtins.length > 1 && missingGtins.length !== index + 1 ? ',' : '' }}
            </li>
          </ul>
        </p-message>
      </transition-group>
    </template>
    <p-column frozen style="min-width: 4rem; flex-grow: 0" class="z-2">
      <template #header>
        <p-checkbox v-model="checkAll" :binary="true" @change="toggleCheckAll" />
      </template>
      <template #body="slotProps">
        <p-checkbox
          v-model="checkedRows"
          name="products"
          :value="slotProps.data"
          @change="stopPropagation($event)"
        />
      </template>
    </p-column>
    <p-column frozen style="min-width: 8rem; flex-grow: 0" class="cursor-pointer z-2">
      <template #body="slotProps">
        <upload class="w-5rem" :upload="slotProps.data.thumbnail" />
      </template>
      <template #editor="slotProps">
        <upload class="w-5rem" :upload="slotProps.data.thumbnail" />
      </template>
    </p-column>

    <p-column
      v-for="(col, index) of columns.filter((item: TableColumn) => item.selected)"
      :key="col.field + '_' + index"
      :field="col.field"
      :header="col.header"
      :sortable="col.sortable"
      :show-filter-match-modes="false"
      style="cursor: pointer"
      :style="'min-width: ' + col.size"
      :hidden="!col.visible && isRetailer"
      flex-wrap:
      nowrap;
    >
      <template #body="{ data }">
        <template v-if="col.type === 'gtin'">
          {{ data.gtin ? data.gtin : '' }}
        </template>
        <template v-if="col.type === 'display_name'">
          {{ data.display_name }}
        </template>

        <template v-if="col.type === 'variant'">
          {{ data.variant_name ? data.variant_name : 'Master Data' }}
        </template>

        <!-- <template v-if="col.type === 'shared_retailer' && data.variant_name != null">
          <p-checkbox v-model="data.public" :binary="true" :disabled="true" />
        </template> -->

        <template v-if="col.type === 'owner'">
          {{ data.owner.name }}
        </template>

        <template v-if="col.type === 'category'">
          {{ data.category ? data.category.name : '' }}
        </template>

        <template v-if="col.type === 'datamodel'">
          {{ data.datamodel ? data.datamodel.name : '' }}
        </template>

        <template v-if="col.type === 'brand'">
          {{ data.brand ? data.brand.name : '' }}
        </template>

        <template v-if="col.type === 'status'">
          <StatusChip
            :label="data.status.label"
            :severity="ActionStatusSeverityEnum[data.status.label?.toUpperCase() as keyof typeof ActionStatusSeverityEnum]"
            label-only
          />
        </template>

        <template v-if="col.type === 'module'">
          {{ data.module ? data.module.name : '' }}
        </template>

        <template v-if="col.type === 'completeness'">
          <ProgressBar :progress="Math.round(data.completeness) ?? 0" width="w-10rem" />
        </template>

        <template v-if="col.type === 'updated_at'">
          {{ formatDate(data.updated_at) }}
        </template>
        <template v-if="col.type === 'images_included'">
          <div class="flex flex-row gap-1">
            <div
              v-for="item in data.images_included.sort((a: ProductUpload, b: ProductUpload) => a.order - b.order)"
              :key="item.id"
            >
              <p-image
                v-if="item.public_thumbnail_url"
                preview
                :alt="item.name"
                image-style="border-radius: 0.25rem"
                :src="item.public_thumbnail_url"
                loading="lazy"
                class="flex h-4rem justify-content-center w-4rem"
              />
              <SvgIcon
                v-else
                :filename="item.public_url"
                custom-style="width: 4rem"
                class="flex-shrink-0"
              />
            </div>
          </div>
        </template>
      </template>

      <template
        v-if="
          col.type === 'variant' ||
          col.type === 'owner' ||
          col.type === 'category' ||
          col.type === 'datamodel' ||
          col.type === 'brand' ||
          col.type === 'module' ||
          col.type === 'status'
        "
        #filter="{ filterModel }"
      >
        <template v-if="col.type === 'variant'">
          <VariantSelect
            v-model="filterModel.value"
            class="filter-style"
            display="comma"
            multiselect
            hide-label
          />
        </template>

        <template v-if="col.type === 'owner'">
          <ClientSelect
            v-model="filterModel.value"
            multiselect
            hide-label
            class="filter-style"
            only-used-by-products
            display="comma"
          />
        </template>

        <template v-if="col.type === 'datamodel'">
          <div class="filter-style">
            <DatamodelSelect
              v-model="filterModel.value"
              :only-used-by-products="true"
              display="comma"
              hide-label
              multiselect
            />
          </div>
        </template>

        <template v-if="col.type === 'category'">
          <CategorySelect
            v-model="filterModel.value"
            class="filter-style"
            display="comma"
            multiselect
            hide-label
          />
        </template>

        <template v-if="col.type === 'brand'">
          <BrandSelect
            v-model="filterModel.value"
            multiselect
            class="filter-style"
            only-used-by-products
            hide-label
            display="comma"
          />
        </template>

        <template v-if="col.type === 'module'">
          <ModuleSelect
            v-model="filterModel.value"
            class="filter-style"
            :only-used-by-products="true"
            display="comma"
            multiselect
            hide-label
          />
        </template>

        <template v-if="col.type === 'status'">
          <StatusSelect
            v-model="filterModel.value"
            class="filter-style"
            display="comma"
            multiselect
            hide-label
          />
        </template>
      </template>
    </p-column>
    <template #empty>
      <div class="w-full">
        <EmptyState
          :translation-service="ts"
          :icon-name="'products'"
          :button-icon="'mdi mdi-plus'"
          @clicked="$emit('create-product')"
        />
      </div>
    </template>
  </pDataTable>
  <pPaginator
    v-if="products.length > 0 && total > perPage && !onlyshowSelectedProducts"
    :rows="perPage"
    style="position: sticky; bottom: 0; width: 100%; z-index: 3"
    template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    :total-records="total"
    :rows-per-page-options="[5, 15, 50, 100, 250, 500]"
    current-page-report-template="Showing {first} to {last} of {totalRecords}"
    @page="onPageChange($event)"
  />
</template>
<style lang="scss" scoped>
.filter-style {
  max-width: 150px;
}

.p-image :deep(img) {
  height: 100%;
  object-fit: contain;
}

.p-datatable :deep(.p-datatable-thead) {
  z-index: 3;
}
.p-datatable {
  overflow: auto;
}
.p-datatable :deep(.p-datatable-loading-overlay) {
  z-index: 5;
}
</style>
